
<template>
    <!-- 横向滚动 -->
    <div class="h-text-scroll">
        <div class="text-scroll-wrap" :class="{'pause': stop ? ispause : false, 'no-loop': !loop}" :style="{'animation-duration': duration + 's',
        '-webkit-animation-duration': duration + 's'}">
            <div class="text-scroll" @mouseenter="ispause=true" @mouseleave="ispause=false" v-for="index in 2" :key="index">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
 
<script>
export default {
    name: "Marquee",
    props: {
        stop: {
            type: Boolean,
            default: false,
        },
        duration: {
            type: Number,
            default: 10,
        },
        loop: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ispause: false,
        };
    },
};
</script>
 
<style scoped>
.h-text-scroll {
    min-height: 30px;
    width: 100%;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
}
.text-scroll-wrap {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    animation-name: mymove;
    -webkit-animation-name: mymove;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    animation-duration: 10s;
    -webkit-animation-duration: 10s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}
.text-scroll-item {
    display: inline-block;
}
.text-scroll {
    display: inline-block;
    min-width: 100%;
}
@keyframes mymove {
    0% {
        margin-left: 0px;
    }
    100% {
        margin-left: -100%;
    }
}
.pause {
    animation-play-state: paused;
    -webkit-animation-play-state: paused;
}
.no-loop {
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
}
</style>