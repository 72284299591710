//我的校区
<template>
  <div class="my_school_info">
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <div class="look_campus_info_content" v-if="isShowInfo">
          <!-- 文字轮播部分-->
          <div class="msg_text" v-if="isShowTip">
            <!-- stop：鼠标悬停是否停止滚动，duration：滚动一次的时间（s），loop：是否循环滚动 -->
            <!-- <Marquee stop :duration="20" loop>
              <span>您校区有部分加盟课程的剩余账户数即将用完,为了不影响您的校区正常运营,请尽快与客服老师联系!</span>
            </Marquee> -->

            <!-- 引入滚动组件 -->
            <BannerScroll :listData="expireArr"></BannerScroll>

            <!--  提示文字 -->
            <span>为了不影响您的校区正常运营,请尽快与客服老师联系!</span>

            <!-- 关闭按钮 -->
            <span @click.stop="isShowTip = false" class="el-icon-close margin_right_12 margin_left_12"></span>
          </div>

          <!-- 学校名称  学校编码 手机号 联系人 全部显示校长 -->
          <div class="top margin_top_14 margin_left_12 margin_right_12">
            <div>学校名称：{{ schoolInfo.school_name }}</div>
            <div class="margin_left_40 margin_right_40">学校编码：{{ schoolInfo.school_id }}</div>
            <div class="search_bt_active copy_bt" @click.stop="doCopyAction">复制</div>
            <div class="margin_left_40 margin_right_40">校长手机号：{{ schoolInfo.principal_tel }}</div>
            <div>校长姓名：{{ schoolInfo.principal_name }}</div>
          </div>

          <!-- 校区地址 -->
          <div class="margin_top_20 margin_left_12 margin_right_12">
            <div>校区地址：{{ schoolInfo.showAddress }}</div>
          </div>

          <!-- 校区logo以及联系人二维码,招生码 -->
          <div class="logo margin_bottom_10 margin_top_20 margin_left_12 margin_right_12">
            <div v-if="schoolInfo.school_logo">校区logo：</div>
            <img v-if="schoolInfo.school_logo" class="log_img margin_left_10" :src="schoolInfo.school_logo" />

            <div v-if="schoolInfo.contacts_code" :class="{ margin_left_30: schoolInfo.school_logo }">招生负责人二维码：</div>
            <img v-if="schoolInfo.contacts_code" class="log_img margin_left_10" :src="schoolInfo.contacts_code" />

            <div v-if="schoolInfo.enroll_code" :class="{ margin_left_30: schoolInfo.contacts_code }">招生码：</div>
            <!-- <img v-if="schoolInfo.enroll_code" class="log_img margin_left_10" :src="schoolInfo.enroll_code"> -->
            <el-image v-if="schoolInfo.enroll_code" :preview-src-list="codeList" class="log_img margin_left_10" :src="schoolInfo.enroll_code" />
          </div>

          <!-- 搜索部分 -->
          <div class="search_content margin_top_14">
            <!-- 加盟的课程级别 -->
            <div class="select_state margin_left_10">
              <span class="margin_right_4">加盟的课程级别：</span>
              <el-select @change="chooseLessonValue" v-model="lessonValue" placeholder="请选择">
                <el-option v-for="item in lessonArr" :key="item" :label="item" :value="item"> </el-option>
              </el-select>
            </div>

            <!-- 加盟状态 -->
            <div class="margin_left_20">
              <span class="margin_right_4">加盟的课程状态：</span>
              <el-select @change="chooseLessonState" v-model="lessonStateValue" placeholder="请选择">
                <el-option v-for="item in lessonSateArr" :key="item" :label="item" :value="item"> </el-option>
              </el-select>
            </div>
          </div>

          <!-- 表格部分 -->
          <div class="dv_table_view_content">
            <el-table ref="StuManagerTable" style="width: 100%" border stripe :data="tableData" :max-height="maxHeight">
              <!-- 序号 -->
              <el-table-column prop="index" align="center" label="序号" width="60"></el-table-column>

              <!-- 加盟的课程  -->
              <el-table-column prop="level_name" align="center" :show-overflow-tooltip="true" label="加盟的课程" width="200"></el-table-column>

              <!-- 加盟时间  -->
              <el-table-column prop="joinTime" align="center" label="加盟时间" width="140"></el-table-column>

              <!-- 加盟状态  -->
              <el-table-column prop="joinState" align="center" label="加盟状态" width="120"></el-table-column>

              <!-- 教师账户余额  -->
              <!-- <el-table-column prop="teacher_balance" align="center" label="教师账户余额" min-width="20%"></el-table-column> -->
              <el-table-column align="center" label="教师账户余额" min-width="20%">
                <template slot-scope="scope">
                  <div class="flex_c" v-if="scope.row.isShowTeacher">
                    <div class="flex_h dv_info" v-for="(item, index) in scope.row.teacherArr" :key="index">
                      <span>{{ item.teacherNum }}(有效期：{{ item.startTime }}~{{ item.endTime }})</span>
                    </div>
                  </div>
                  <div v-else>
                    <span>{{ scope.row.teacherMsg }}</span>
                  </div>
                </template>
              </el-table-column>

              <!-- 学生账户余额  -->
              <el-table-column align="center" label="学生账户余额" min-width="20%">
                <template slot-scope="scope">
                  <div class="flex_c" v-if="scope.row.isShowStudent">
                    <div class="flex_h dv_info" v-for="(item, index) in scope.row.studentArr" :key="index">
                      <span> {{ item.studentNum }}</span>
                      <span>(有效期：{{ item.startTime }}~{{ item.endTime }})</span>
                    </div>
                  </div>
                  <div v-else>
                    <span>{{ scope.row.studentMsg }}</span>
                  </div>
                </template>
              </el-table-column>

              <!-- 操作部分 -->
              <el-table-column label="操作" align="center" width="140">
                <template slot-scope="scope">
                  <div class="look_info" @click.stop="doLookDetail(scope.row)">查看账户明细</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <!-- 没有数据时 -->
        <el-empty style="height: 100%" description="暂无校区信息" v-else></el-empty>
      </template>
    </Layout>

    <!-- 账户明细弹框 -->
    <LookAccountDetails
      :levelId="levelId"
      :schoolId="schoolId"
      :pid="pid"
      :subId="subId"
      title="账户明细"
      @close="isShowAccountDialog = false"
      :showDialog="isShowAccountDialog"
      v-if="isShowAccountDialog"
    ></LookAccountDetails>
  </div>
</template>
<script>
//账户明细弹框
import LookAccountDetails from "@/components/dialog/LookAccountDetails";

//跑马灯组件
import Marquee from "@/components/Marquee";
//滚动组件
import BannerScroll from "../../components/seamlscroll/BannerScroll.vue";

export default {
  data() {
    return {
      //加载loading
      showLoading: -1,

      //表格的最大高度
      maxHeight: 0,

      //学校对象
      schoolInfo: {},

      //服务器返回的所有数据
      serverData: [],

      //表格数据
      tableData: [],

      //是否显示账户明细弹框
      isShowAccountDialog: false,

      //查看账户明细弹框时传递的schoolId
      schoolId: "",

      //查看账户明细弹框时传递的pid
      pid: "",

      //查看账户明细弹框时传递的subId
      subId: "",

      //查看账户明细弹框时传递的levelId
      levelId: "",

      //是否显示校区的标识
      isShowInfo: false,

      //是否显示滚动文字信息
      isShowTip: false,

      //招生码的查看大图
      codeList: [],

      //加盟的课程级别
      lessonValue: "所有",

      //加盟的课程级别数据
      lessonArr: ["所有"],

      //加盟的课程状态
      lessonStateValue: "所有",

      //加盟的课程状态数据
      lessonSateArr: ["所有"],

      //到期的课程级别数据集合
      expireArr: [],
    };
  },
  created() {
    //请求表格数据
    this.http();
  },

  mounted() {},

  methods: {
    //初始化表格数据
    initData() {
      let clientH = document.documentElement.clientHeight;
      //只要校区logo,招生码log以及联系人二维码有一个不为空,则表格高度需减去logo部分高度
      if (this.schoolInfo.contacts_code || this.schoolInfo.school_logo || this.schoolInfo.enroll_code) {
        this.maxHeight = clientH - 380;
      } else {
        this.maxHeight = clientH - 260;
      }

      //初始化产看大图的集合
      if (!this.isEmpty(this.schoolInfo.enroll_code)) {
        this.codeList.push(this.schoolInfo.enroll_code);
      }
    },

    //请求表格数据
    http() {
      let param = this.getHttpParams();
      param.type = 3;
      this.$http.fetchPost(this.$api.SCHOOL_MANAGER, param).then((res) => {
        //显示内容布局
        this.showLoading = 1;
        let result = res.data;
        if (result.state == 200) {
          if (!this.isEmpty(result.data.school_id)) {
            //有校区id时展示学校信息

            //获取学校对象
            let item = result.data;
            //拼接一下显示地址
            let showAddress = "";
            if (!this.isEmpty(item.province) && !this.isEmpty(item.city)) {
              if (item.province != item.city) {
                //不是直辖市,省和市不同
                if (!this.isEmpty(item.county)) {
                  showAddress = item.province + item.city + item.county;
                } else {
                  showAddress = item.province + item.city;
                }
              } else {
                //直辖市
                if (!this.isEmpty(item.county)) {
                  showAddress = item.province + item.county;
                } else {
                  showAddress = item.province;
                }
              }
            }

            //判断地址是否为空,不为空的情况在进行展示
            if (!this.isEmpty(item.address) && item.address != "undefined") {
              showAddress += item.address;
            }

            if (!this.isEmpty(showAddress)) {
              this.$set(item, "showAddress", showAddress);
            } else {
              this.$set(item, "showAddress", "-");
            }
            this.schoolInfo = item;

            //根据服务器返回的数据计算表格高度
            this.initData();

            //获取表格数据
            let serverArr = result.data.level_order;
            // console.log("请求到的数据是:", serverArr);
            if (!this.isEmpty(serverArr)) {
              serverArr.forEach((item, index) => {
                //创建表格数据
                //创建序号
                this.$set(item, "index", index + 1);

                //设置加盟的课程
                item.level_name = item.sub_name + item.level_name;

                //加盟的时间
                this.$set(item, "joinTime", item.start_date);

                //加盟状态
                this.$set(item, "joinState", this.getStrByState(item.state));

                //创建老师账户余额
                let teacherStr = item.teacher_order;
                if (!this.isEmpty(teacherStr)) {
                  let teacherArr = [];
                  //先用_切割
                  let firstArr = teacherStr.split("_");
                  if (!this.isEmpty(firstArr)) {
                    firstArr.forEach((levelItem) => {
                      let teacherInfo = new Object();
                      //在使用+切割
                      let secondArr = levelItem.split("+");
                      //数量
                      teacherInfo.teacherNum = secondArr[0];
                      //开始有效期
                      teacherInfo.startTime = secondArr[1];
                      //结束有效期
                      teacherInfo.endTime = secondArr[2];

                      //剩余时间
                      teacherInfo.remainTime = this.getDiffDay(secondArr[2]);

                      //所属课程
                      teacherInfo.sub_name = item.sub_name;

                      //所属级别,注意这已经拼接了课程名称
                      teacherInfo.level_name = item.level_name;

                      teacherArr.push(teacherInfo);
                    });
                  }
                  this.$set(item, "isShowTeacher", true);
                  this.$set(item, "teacherArr", teacherArr);
                } else {
                  //没有老师账户时显示的提示-
                  this.$set(item, "teacherMsg", "-");
                  this.$set(item, "isShowTeacher", false);
                }

                //创建学生账户余额
                let studentStr = item.student_order;
                if (!this.isEmpty(studentStr)) {
                  let studentArr = [];
                  //先用_切割
                  let firstArr = studentStr.split("_");
                  if (!this.isEmpty(firstArr)) {
                    firstArr.forEach((levelItem) => {
                      let studentInfo = new Object();
                      //在使用+切割
                      let secondArr = levelItem.split("+");
                      //数量
                      studentInfo.studentNum = secondArr[0];
                      //开始有效期
                      studentInfo.startTime = secondArr[1];
                      //结束有效期
                      studentInfo.endTime = secondArr[2];

                      //剩余时间
                      studentInfo.remainTime = this.getDiffDay(secondArr[2]);

                      //所属课程
                      studentInfo.sub_name = item.sub_name;

                      //所属级别,注意这已经拼接了课程名称
                      studentInfo.level_name = item.level_name;

                      studentArr.push(studentInfo);
                    });
                  }
                  this.$set(item, "studentArr", studentArr);
                  this.$set(item, "isShowStudent", true);
                } else {
                  //没有学生账户时显示的提示-
                  this.$set(item, "studentMsg", "-");
                  this.$set(item, "isShowStudent", false);
                }

                //表格数据
                this.tableData.push(item);

                //记录服务器返回的总数据
                this.serverData.push(item);
              });

              // console.log("创建好的数据是：", this.serverData);

              //初始化课程级别和课程状态的下拉框数据
              this.initSelectData();

              //初始化是否显示顶部的提示文字信息
              this.isShowTip = this.getTipState();

              //初始化到期的课程级别数据集合
              this.initExpireArr();

              // console.log("显示的值是:", this.isShowTip);

              // console.log("创建好的轮播数据是:", this.expireArr);
            }
            this.isShowInfo = true;
          } else {
            //没有校区ID显示空视图
            this.isShowInfo = false;
          }
        } else {
          this.showErrorMsg(this.getMesage(result.state));
        }
      });
    },

    //判断是否显示顶部提示文字信息的方法
    //判断方法--单个课程级别账号有效期小于等于14天时,系统条提示
    //您的校区的xxxx课程xxx级别教师/学生账户即将到期,为了不影响您校区的正常运营,请尽快与客户老师联系!
    getTipState() {
      let res = false;
      for (let i = 0; i < this.tableData.length; i++) {
        let item = this.tableData[i];

        if (item.state == 0 || item.state == 1) {
          //只有试用和正常状态才去判定账户数是否需要提示
          if (item.isShowTeacher) {
            //在判断老师账户中是否有小于等于14天的 如果有这res=true,否则为false
            let isOverTeacher = this.isOverTeacher(item.teacherArr);

            if (isOverTeacher) {
              res = true;
              break;
            }
          }
          if (item.isShowStudent) {
            //在判断学生账户中是否有小于等于14天的 如果有这res=true,否则为false
            let isOverStudent = this.isOverStudent(item.studentArr);
            if (isOverStudent) {
              res = true;
              break;
            }
          }
        }
      }

      return res;
    },

    //初始化到期的课程级别数据集合
    initExpireArr() {
      for (let i = 0; i < this.tableData.length; i++) {
        let item = this.tableData[i];
        //取出老师数据和学生数据
        if (item.state == 0 || item.state == 1) {
          //只有试用状态和正式状态才去判定是否进行账号到期提醒
          let teacherArr = item.teacherArr;
          if (!this.isEmpty(teacherArr) && teacherArr.length > 0) {
            teacherArr.forEach((item) => {
              // item.teacherNum != 0 &&
              if (item.remainTime <= 14) {
                if (!this.isEmpty(item.level_name)) {
                  this.expireArr.push(`校区的${item.level_name}教师账户即将到期`);
                }
              }
            });
          }

          let stuArr = item.studentArr;
          if (!this.isEmpty(stuArr) && stuArr.length > 0) {
            stuArr.forEach((item) => {
              //item.studentNum != 0 &&
              if (item.remainTime <= 14) {
                if (!this.isEmpty(item.level_name)) {
                  this.expireArr.push(`校区的${item.level_name}学生账户即将到期`);
                }
              }
            });
          }
        }
      }
    },

    //判断老师账号是否有14天到期的
    isOverTeacher(teacherArr) {
      let res = false;
      for (let i = 0; i < teacherArr.length; i++) {
        if (teacherArr[i].remainTime <= 14) {
          //有到期
          res = true;
          break;
        }
      }
      return res;
    },

    //判断学生账号是否有14天到期的
    isOverStudent(studentArr) {
      let res = false;
      for (let i = 0; i < studentArr.length; i++) {
        if (studentArr[i].remainTime <= 14) {
          //有到期
          res = true;
          break;
        }
      }
      return res;
    },

    //获取每个课程的老师数量总数
    getTeacherNum(teacherArr) {
      let res = 0;
      teacherArr.forEach((item) => {
        res += item.teacherNum;
      });
      return res;
    },

    //获取每个课程的老师数量总数
    getStudentNum(studentArr) {
      let res = 0;
      studentArr.forEach((item) => {
        res += item.studentNum;
      });
      return res;
    },

    //判断指定时间和当前时间差几天
    getDiffDay(endTime) {
      let totalDays, diffDate;
      let myDate_1 = Date.parse(endTime);
      diffDate = Math.abs(myDate_1 - Date.now());
      totalDays = Math.floor(diffDate / (1000 * 3600 * 24));
      return totalDays + 1;
    },

    // 复制操作
    doCopyAction() {
      let temp = document.createElement("textarea");
      temp.value = this.schoolInfo.school_id + "";
      document.body.appendChild(temp);
      temp.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      temp.style.display = "none";
      this.showSuccessMsg("复制成功");
    },

    //获取加盟状态 空:所有 0:试用  1:正常 -1:失效到期 -2:禁用 -3:待完善
    getStrByState(state) {
      let res = "";
      switch (Number.parseInt(state)) {
        case 0:
          res = "试用";
          break;
        case 1:
          res = "正常";
          break;
        case -1:
          res = "失效到期";
          break;
        case -2:
          res = "禁用";
          break;
        case -3:
          res = "待完善";
          break;
        default:
          break;
      }

      return res;
    },

    //查看账户明细
    doLookDetail(item) {
      // console.log("点击查看账户明细了", item);
      this.schoolId = item.school_id;
      this.pid = item.pid;
      this.subId = item.sub_id;
      this.levelId = item.level_id;
      this.isShowAccountDialog = true;
    },

    //选择加盟的课程级别
    chooseLessonValue(val) {
      this.lessonValue = val;
      //同步表格数据
      this.initTableData();
    },

    //选择加盟的课程级别
    chooseLessonState(val) {
      this.lessonStateValue = val;
      //同步表格数据
      this.initTableData();
    },

    //初始化下拉框的数据
    initSelectData() {
      if (!this.isEmpty(this.serverData)) {
        this.serverData.forEach((item) => {
          //课程级别
          if (!this.lessonArr.includes(item.level_name)) {
            this.lessonArr.push(item.level_name);
          }
          //课程状态
          if (!this.lessonSateArr.includes(item.joinState)) {
            this.lessonSateArr.push(item.joinState);
          }
        });
      }
    },

    //根据下拉框的选中值来同步表格的数据
    initTableData() {
      //先清空表格数据
      this.tableData.splice(0, this.tableData.length);
      this.serverData.forEach((item) => {
        //这里判断三种情况 课程级别所有  课程状态所有
        if (this.lessonValue == "所有" && this.lessonStateValue != "所有") {
          //课程级别 所有
          if (item.joinState == this.lessonStateValue) {
            this.tableData.push(item);
          }
        } else if (this.lessonValue != "所有" && this.lessonStateValue == "所有") {
          //课程状态 所有
          if (item.level_name == this.lessonValue) {
            this.tableData.push(item);
          }
        } else if (this.lessonValue != "所有" && this.lessonStateValue != "所有") {
          //都不是 所有
          if (item.joinState == this.lessonStateValue && item.level_name == this.lessonValue) {
            this.tableData.push(item);
          }
        } else {
          //都是 所有
          this.tableData.push(item);
        }
      });

      //重新更新一下序号
      if (!this.isEmpty(this.tableData)) {
        this.tableData.forEach((item, index) => {
          this.$set(item, "index", index + 1);
        });
      }
    },
  },

  computed: {},
  components: {
    LookAccountDetails,
    Marquee,
    BannerScroll,
  },
};
</script>
<style lang="scss" scoped>
.my_school_info {
  width: 100%;
  height: calc(100vh - 77px);
  .look_campus_info_content {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    position: relative;

    .msg_text {
      width: 100%;
      padding: 4px 0;
      display: flex;
      background: rgba($color: #ffad99, $alpha: 0.8);
      align-items: center;

      .el-icon-close {
        font-size: 18x;
        cursor: pointer;
      }
    }

    .top {
      display: flex;
      align-items: center;

      .copy_bt {
        padding: 3px 20px;
        border-radius: 4px;
      }
    }

    .logo {
      display: flex;
      .log_img {
        width: 120px;
        height: 120px;
        object-fit: cover;
      }
    }

    //选择框
    .search_content {
      display: flex;
      align-items: center;
      ::v-deep .el-input__inner {
        height: 30px;
        line-height: 30px;
        padding-left: 6px;
      }

      ::v-deep .el-input__icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ::v-deep .el-input {
        width: 210px;
      }

      ::v-deep .el-input__suffix {
        right: 0px;
      }
    }

    //表格内容部分
    .dv_table_view_content {
      width: 98%;
      margin: 16px auto 0;
      min-width: 1100px;
      display: flex;
      justify-content: center;
    }
  }

  .look_info {
    font-size: 12px;
    color: #409eff;
    cursor: pointer;
  }

  .dv_info {
    display: flex;
    font-size: 14px;
    align-items: center;
    line-height: 32px;
  }
}
</style>
