<!-- 滚动组件 -->
<template>
  <vue-seamless-scroll :data="listData" :class-option="optionHover" class="seamless-warp">
    <ul class="item">
      <li v-for="item in listData">
        <span class="tv_red">{{ item }}</span>
      </li>
    </ul>
  </vue-seamless-scroll>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  watch: {},
  // 监听属性 类似于data概念
  computed: {
    optionHover() {
      return {
        hoverStop: false,
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  components: {
    vueSeamlessScroll,
  },
  props: ["listData"],
};
</script>

<style lang="scss" scoped>
.seamless-warp {
  flex: 1;
  margin: 0 14px;
  height: 20px;
  overflow: hidden;
}
</style>
